document.addEventListener('DOMContentLoaded', function() {
  // メディアクエリの条件
  // mql = window.matchMedia(mediaQueryString)
  const mediaQuery = window.matchMedia('(min-width: 1024px)');
  // 初期表示の処理
  if (mediaQuery.matches) {
    // `mediaQuery`が`true`の場合の処理
  } else {
    // `mediaQuery`が`false`の場合の処理
  }

  // リスナーを追加して、ブレイクポイントが変わったタイミングで関数を実行する
  mediaQuery.addListener(changeBreakpoint);

  function changeBreakpoint() {
    if (mediaQuery.matches) {
      // `mediaQuery`が`true`の場合の処理
    } else {
      // `mediaQuery`が`false`の場合の処理
    }
  }
});


/**
 * Region切り替えのトグルです。スマホとPCは別のHTMLです。
 * 展開時に他のナビゲーションが開いていた場合は、他のナビゲーションを強制的に閉じます。
 */
$(function() {
  var slideSpeed = 300;
  var mediaQuery = window.matchMedia('(min-width: 1024px)');

  // 初期表示
  var init = function() {

    if (mediaQuery.matches) {
      $('.st-DropNav_PcButton').on('click', function() {
        closeSearch();
        closeRegion();
      });

      $('.st-SearchButton').on('click', function() {
        closeDropNav();
        closeRegion();
      });

      $('.st-GlobalRegionPc_Toggle').on('click', function() {
        closeGlobalSiteNav();
        closeDropNav();
        closeSearch();
      });

      $(window).on('click', function(e){
        var clickedArea = e.target.parentElement.className;
        var EXCLUDED_CLASS = [
        'st-DropNav_PcButtonArea',
        'st-GlobalRegionPc'
        ];
        clickedClasses = clickedArea.split(' ');
        notExcludedClass = true;

        for( var i=0; i < clickedClasses.length; i++ ){
          if(EXCLUDED_CLASS.indexOf(clickedClasses[i]) !== -1 ) {
            notExcludedClass = false;
            break;
          }
        }

        if (notExcludedClass) {
          closeDropNav();
          closeRegion();
        }

      });

    }

    // ブレイクポイントが切り替わった時にDOMを変更する
    mediaQuery.addListener(changeBreakpoint);
  }();

  function changeBreakpoint() {
    if (mediaQuery.matches) {
      // PC
      $('.st-DropNav_PcButton').on('click', function() {
        closeSearch();
        closeRegion();
      });
      $('.st-SearchButton').on('click', function() {
        closeDropNav();
        closeRegion();
      });
      $('.st-GlobalRegionPc_Toggle').on('click', function() {
        closeGlobalSiteNav();
        closeDropNav();
        closeSearch();
      });
      $('.st-GlobalNav_RegionClose').on('click', function() {
        closeGlobalSiteNav();
        closeDropNav();
        closeSearch();
        closeRegion();
      });
    }
  }

  function closeGlobalSiteNav() {
    $('.st-GlobalSiteNav_Link-hasChildren').attr('aria-expanded', 'false');
    $('.st-GlobalSiteNav_SubArea').attr('aria-hidden', 'true').slideUp(slideSpeed);
    $('.st-GlobalNav_NavClose').attr('aria-hidden', 'true');
  }
  function closeDropNav() {
    $('.st-DropNav_PcButton').attr('aria-expanded', 'false');
    $('.st-DropNav').attr('aria-hidden', 'true').slideUp(slideSpeed);
    $('#st-DropNav_Close').hide().attr('aria-hidden', 'true');
  }
  function closeRegion() {
    $('.st-GlobalRegionPc_Toggle').attr('aria-expanded', 'false');
    $('.st-GlobalRegionPc_SubArea').attr('aria-hidden', 'true').slideUp(slideSpeed);
    $('.st-GlobalNav_RegionClose').attr('aria-hidden', 'true').hide();
  }
  function closeSearch() {
    $('.st-SearchButton').attr('aria-expanded', 'false');
    $('.st-SearchForm').attr('aria-hidden', 'true').slideUp(slideSpeed);
    $('.st-GlobalNav_SearchClose').attr('aria-hidden', 'true');
  }

}());

/**
 * Regionクリック時、パスを維持して他の国へ遷移。
 */
$(function() {

  /**
   * コンタクトフォームの場合、パスを維持せずhrefに従って画面遷移する
   * 
   * フォームは各国パスが共通でないことと、各国間の移動を考慮するとパラメータの引き継ぎなど考慮事項が多くなるため
   * コンタクトフォームはbodyにid="contact"を含むのでそれをもとに識別
   */ 
  if ($('body#contact').length) {
    return;
  }

  $('.st-GlobalRegionPc_RegionLink, .st-GlobalRegionSp_RegionLink').on('click', function(e) {
    var match = location.pathname.match(/\/(?:([a-zA-z]{2})\/)?(.*)/);
    var path = (match[1]) ? '/' + match[2] : location.pathname;
    if (path) {
      path = path.replace(/^\//, '');
      var href = $(e.currentTarget).attr('href') + path;
      if (href) {
        window.location.href = href;

        e.preventDefault();
        e.stopPropagation();
      }
    }
  });
}());
