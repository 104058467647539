$(function() {
  var $link = $('.st-CategoryNav_Link');
  var $parent = $('.st-CategoryNav_Parent');
  var locationPathname = getPathName();
  var directories = locationPathname.split("/");
  // var category = '/' + directories[1] + '/';
  var thirdLabel = '/' + directories[1] + '/' + directories[2] + '/' + directories[3] + '/';
  var fourthLabel = '/' + directories[1] + '/' + directories[2] + '/' + directories[3] + '/' + directories[4] + '/';

  var init = function() {
    addCurrentLink();
    addCurrentParent();
  }();

  /**
   * パスネーム取得
   *
   * cms環境の場合、先頭に/eu/といった国ディレクトリが入るためそれを除く
   * @return string
   */
  function getPathName() {
    var match = location.pathname.match(/\/(?:([a-zA-z]{2})\/)?(.*)/);
    return (match[1]) ? '/' + match[2] : location.pathname;
  }

  /**
   * URLと同じリンクに`aria-current`を追加します。
   */
  function addCurrentLink() {
    $link.each(function(index, elm) {
      if (locationPathname.replace(/index\.html$/, '') === $(elm).data('mark-current')) {
        // 同じURLであればカレント表示する
        $(elm).attr('aria-current', 'page');
      }
    });
  }

  /**
   * URLと同じリンクか、親に当たるリンクに`aria-current`を追加します。
   */
  function addCurrentParent() {
    $parent.each(function(index, elm) {
      var $elm = $(elm);
      var mark = $elm.data('mark-current');
      var isThirdLabelLocation = thirdLabel === mark;
      var isFourthLocation = fourthLabel === mark;
      var isPage = getPathName().replace(/index\.html$/, '') === mark;

      if (isPage) {
        $elm.attr('aria-current', 'page');
        return;
      }

      if (isThirdLabelLocation || isFourthLocation) {
        $elm.attr('aria-current', 'location');
      }
    });
  }

}());
