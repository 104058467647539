/*
 * リンクアイコン
 * 
 * MTコンポーネント用リンクアイコン
 * .swm-LinkIconA, Bの要素にアイコンを付与します
 */
$(function () {
  var appendLink = function(aClass, iconClass) {
    // aタグ内の特定要素にアイコンをつけたい場合はjs-swmLinkIconで指定されている
    var $iconElm = $('a.' + aClass).find('.js-swmLinkIcon');
    if (0 === $iconElm.length) {
      // なければaタグ自体にアイコンを追加
      $iconElm = $('a.' + aClass);
    }
    $iconElm.append($('<span class="' + iconClass + '" aria-hidden="true"></span>'));
  };
  appendLink('swm-LinkIconA', 'swm-LinkIconA_Icon');
  appendLink('swm-LinkIconB', 'swm-LinkIconB_Icon');
  appendLink('swm-LinkIconPdf', 'swm-LinkIconPdf_Icon');
  appendLink('swm-LinkIconArrow', 'swm-LinkIconArrow_Icon');
}());