$(function() {
  // `touchEnabled: true`だとChromeで[Violation]が出てしまう。
  // Prev,Nextのコントロールを表示しているときには`false`にしてタッチ操作をしないようにする。
  // TODO: リサイズ対応
  var $controls = $('.home-Carousel_ThumbnailLink, .home-Carousel_Control li');
  var spOptions = {
    // 初期表示をランダムで始める
    randomStart: true,
    mode: 'fade',
    touchEnabled: true,
    // PrevとNextを表示する
    controls: true,
    // スライドを自動で切り替える
    auto: true,
    // スライドの停止時間
    pause: 4000,
    // マウスオンするとスライドが一時停止する
    autoHover: true,
    // Prev,Nextのコントロール
    pagerSelector: '.home-Carousel_Control',
    prevSelector: '.home-Carousel_ControlPrev',
    nextSelector: '.home-Carousel_ControlNext',
    // 各スライドのコントロール
    pagerCustom: '.home-Carousel_Thumbnails'
  };
  var pcOptions = {
    // 初期表示をランダムで始める
    randomStart: true,
    mode: 'fade',
    touchEnabled: false,
    // PrevとNextを表示する
    controls: true,
    // スライドを自動で切り替える
    auto: true,
    // スライドの停止時間
    pause: 4000,
    // マウスオンするとスライドが一時停止する
    autoHover: true,
    // Prev,Nextのコントロール
    pagerSelector: '.home-Carousel_Control',
    prevSelector: '.home-Carousel_ControlPrev',
    nextSelector: '.home-Carousel_ControlNext',
    // 各スライドのコントロール
    pagerCustom: '.home-Carousel_Thumbnails'
  };

  var isSp = checkIsSp();

  function checkIsSp() {
    var width = $(window).innerWidth();
    if (width >= 768) {
      return false;
    }
    return true;
  }

  if (isSp) {
    var carousel = $('.home-Carousel_Items').bxSlider(spOptions);
  } else {
    var carousel = $('.home-Carousel_Items').bxSlider(pcOptions);
  }

  // // コントロールをマウスオンするとスライドが一時停止する
  $controls.hover(
    function() {
      carousel.stopAuto();
    },
    function() {
      carousel.startAuto();
    }
  );

});
