/*
 * リストアイコン
 * 
 * MTコンポーネント用リストアイコン
 * .swm-ListArrow_Item要素にアイコンを付与します
 */
$(function () {
  var appendListIcon = function(aClass) {
    var $iconElm = $('.' + aClass);
    $iconElm.prepend($('<span class="sw-ListArrow_Icon" aria-hidden="true"></span>'));
  };
  appendListIcon('swm-ListArrow_Item');
}());