/**
 * タイトルエリアを通り過ぎたら固定表示にします。
 */
$(function() {
  var $title = $('.keyTechnologies-ContentsHeader_Title');

  if (!$title.length) return;

  var titleHeight = getTitleHeight();
  var titleOffsetTop = getTitleOffsetTop();
  var fixedClass = 'keyTechnologies-ContentsHeader_Title-fixed';
  var wait = (1000 / 60); // 60fps
  var isFixed = checkFixed();

  function checkFixed() {
    return $('.st-ToggleButton').attr('aria-expanded') === 'false';
  }

  function getTitleHeight() {
    return $title.outerHeight(true);
  }

  function getTitleOffsetTop() {
    return $title.offset().top;
  }

  function toggleFixed(flag) {
    var scroll = $(window).scrollTop();
    if (!flag) return;

    if (scroll > titleOffsetTop) {
      $title.addClass(fixedClass);
    } else {
      $title.removeClass(fixedClass);
    }
  }

  $(window).on('resize', _.throttle(function() {
    titleHeight = getTitleHeight();
    titleOffsetTop = getTitleOffsetTop();
  }, wait));

  $(window).on('scroll', _.throttle(function() {
    isFixed = checkFixed();
    toggleFixed(isFixed);
  }, wait));

});

