$(function() {
  var trigger = document.querySelectorAll('.js-modal-trigger');

  var modalHTML =
  '<div class="sw-Dialog" id="js-modal">' +
  '  <div class="sw-Dialog_Wrap" id="js-modal-focus" role="dialog" aria-modal="true" tabindex="-1">' +
  '    <div class="sw-Dialog_Inner">' +
  '      <button class="sw-Dialog_Close js-modal-close" aria-label="close">' +
  '        <span></span>' +
  '      </button>' +
  '      <div class="sw-Dialog_Content" id="js-modal-content"></div>' +
  '    </div>' +
  '  </div>' +
  '  <div class="sw-Dialog_Overlay" id="js-modal-overlay"></div>' +
  '</div>';

  function dialog() {
    window.addEventListener('DOMContentLoaded', function() {
      if (trigger.length) {
        document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', modalHTML);
      }
    });

    window.addEventListener('load', function() {
      if (trigger.length) {
        var modal = new Modal({
          afterOpen: function(_, content) {
            // iframeが含まれていたら、自動再生する
            var hasIframe = content.getElementsByTagName('iframe').length ? true : false;

            if (hasIframe) {
              var iframe = document.querySelector('#js-modal').querySelector('iframe');
              var item = content.getElementsByTagName('iframe')[0];
              var currentSrc = item.getAttribute('src').split('?')[0];

              iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
              iframe.setAttribute('src', currentSrc + '?rel=0&autoplay=1&enablejsapi=1');
            }
          }
        });
        modal.init();
      }
    });
  }
  dialog();
}());
