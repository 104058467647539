$(function() {
  var $menus = $('.l-DetailTemplate_SubNavSp');

  var init = function() {
    showProductsMenu();
  }();

  /**
   * パスネーム取得
   * 
   * cms環境の場合、先頭に/eu/といった国ディレクトリが入るためそれを除く
   * @return string
   */
  function getPathName() {
    var match = location.pathname.match(/\/(?:([a-zA-z]{2})\/)?(.*)/);
    return (match[1]) ? '/' + match[2] : location.pathname;
  }

  /**
   * スマホ用メニューの表示を切り替えます。
   * 
   * 2, 3階層目の製品の場合、対応するスマホ用製品メニューだけ表示。他は非表示。
   * cms再構築負荷の軽減のため、js制御しています。
   */
  function showProductsMenu() {
    $menus.each(function(index, elm) {
      var $elm = $(elm);
      var product2DirPath = $(elm).data('mark-current').replace(/index\.html$/, '');
      // URLに第2階層目のURLを含む
      if (getPathName().match(product2DirPath)) {
        $elm.attr('aria-hidden', 'false');
      }
    });
  }
}());
