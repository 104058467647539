$(function() {
  document.addEventListener('DOMContentLoaded', function() {
    var root = document.querySelector('.keyTechnologies-HeroA');

    if (!root) {
      return;
    }

    root.classList.add('-imageLoaded');
  })

}());

