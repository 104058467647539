/**
 * Cookie取得の同意エリアです。
 * 利用者が許可をするまで画面下部に固定されます。
 * コンテンツと被らないようにするため、`body`に`padding-bottom`を動的に指定します。
 */
$(function() {
  var allowCookie = document.getElementById('st-AllowCookie');
  if (!allowCookie) return;
  var allowCookieHeight = getAllowCookieHeight();
  var allowCookieButton = document.getElementById('st-AllowCookie_Button');
  var body = document.getElementsByTagName('body')[0];
  var cookieClassName = 'st-AllowCookie_Show';
  var cookie = document.cookie;
  var PERMISSION_NAME = 'cookie_permission=true';
  var PERMISSION_PATH = 'path=/';
  var isCookieAllowed = checkCookieAllowed();
  var wait = (1000 / 60); // 60fps

  var init = function() {
    if (isCookieAllowed) {
      hide();
    } else {
      addCookieAllowedHeightToBody();
      show();
      focus();

      window.addEventListener('resize', _.throttle(function() {
        allowCookieHeight = getAllowCookieHeight();
        addCookieAllowedHeightToBody();
      }, wait));
    }

    onClickAllow();
  }();

  function show() {
    allowCookie.style.bottom = '0';
    body.classList.add(cookieClassName);
  }

  function hide() {
    allowCookie.style.display = 'none';
    body.style.paddingBottom = '';
    body.classList.remove(cookieClassName);
  }

  function focus() {
    allowCookie.setAttribute('tabindex', '0');
    allowCookie.focus();
  }

  function removeFocus() {
    allowCookie.setAttribute('tabindex', '-1');
  }

  function getAllowCookieHeight() {
    return allowCookie.clientHeight;
  }

  function addCookieAllowedHeightToBody() {
    body.style.paddingBottom = allowCookieHeight + 'px';
  }

  function checkCookieAllowed() {
    if (cookie.indexOf(PERMISSION_NAME) !== -1) {
      return true;
    }
    return false;
  }

  function onClickAllow() {
    allowCookieButton.addEventListener('click', function(e) {
      removeFocus();
      hide();
      setCookie();
      e.stopPropagation();
    });
  }

  function setCookie() {
    document.cookie = PERMISSION_NAME + '; ' + PERMISSION_PATH;
  }

  function deleteCookie() {
    document.cookie = PERMISSION_NAME + '; max-age=0';
  }

}());
