/*
 * アコーディオンメニュー
 * 
 * MTコンポーネント用アコーディオンメニュー
 * button.swm-AccordionA_Tabで囲まれた箇所を、アコーディオンメニューの内容にします
 */
$(function () {

  var tabClass = 'is-active';
  var panelClass = 'is-active';

  /**
   * 初期設定：
   * タブをすべて非表示にする。
   */
  var hideTab = function ($tabs, $tabPanels) {
    $tabs.attr('aria-expanded', 'false').removeClass(tabClass);
    $tabPanels.attr('aria-hidden', 'true').removeClass(panelClass);
  }

  /**
   * アコーディオンを設定
   */
  var setAccordion = function ($tab, $tabPanel, $tabs, $tabPanels, id) {

    /**
     * 初期設定：
     * 各要素にrole属性を付与する。
     */
    $tab.attr('role', 'tab');
    $tabPanel.attr('role', 'tabpanel');

    /**
     * 初期設定：
     * `$tabs`をフォーカス可能にする。
     */
    $tab.attr('tabindex', '0');

    /**
     * 初期設定：
     * 各要素を紐付けるためのIDを付与する。
     */
    $tab.attr({
      'id': 'accordion' + id,
      'aria-controls': 'accordion-panel' + id
    });

    $tabPanel.attr({
      'aria-labelledby': 'accordion' + id,
      'id': 'accordion-panel' + id
    });

    /**
     * タブがクリック・タップされたら、該当するタブを表示する。
     */
    $tab.on('click', function (e) {
      var $thisTab = $(this);
      var controls = $thisTab.attr('aria-controls');

      // 閉じているタブをクリックした場合
      if ($thisTab.attr('aria-expanded') === 'false') {
        $thisTab.attr('aria-expanded', 'true').addClass(tabClass);
        $tabPanels.each(function () {
          if ($(this).attr('id') === controls) {
            $(this).attr('aria-hidden', 'false').addClass(panelClass);
          }
        });
      } else {
        // 開いているタブをクリックした場合
        $thisTab.attr('aria-expanded', 'false').removeClass(tabClass);
        $tabPanels.each(function () {
          if ($(this).attr('id') === controls) {
            $(this).attr('aria-hidden', 'true').removeClass(panelClass);
          }
        });
      }

      e.preventDefault();
    });

    /**
     * キーボード操作。
     * 左右の矢印キーでフォーカスを動かす。上で戻り、下で進む。
     * フォーカスは行き止まりにならず、ループする。
     * enterかスペースを押したときも、クリックイベントと同様の処理をする。
     */
    $tab.on('keydown', function (e) {
      var index = $tabs.index(this);
      if (e.which == 38) {
        index--;
      } else if (e.which == 40) {
        index++;
        // 最後のタブまで来たら最初のタブに戻る。
        if (index === $tabs.length) {
          index = 0;
        }
      }
      // 上下の矢印キー。
      if (e.which == 38 || e.which == 40) {
        $tabs.get(index).focus();
      }
      // enterかスペースキー。
      if (e.which === 13 || e.which === 32) {
        $(this).click();
        $(this).focus();
        e.preventDefault();
      }
    });

  };

  /**
   * 要素のグルーピング
   * Tab内で囲まれた範囲をラップするPanel要素を追加
   */
  var tabPanelClass = "swm-AccordionA_Panel";
  var $tabs = $('.swm-AccordionA_Tab');

  // Tabの不要なラップ要素を削除
  // pタグが残るケースが有るための対応
  $tabs.each(function(){
    var $tab = $(this);
    var safeCount = 100;
    var count = 0;
    var $parent = $tab.parent();
    while ($parent.length && (($parent.prop('tagName') === 'P') || ($parent.prop('tagName') === 'SPAN'))) {
      $tab = $tab.unwrap();
      $parent = $tab.parent();
      if (safeCount <= count) {
        break;
      }
      count++;
    }
  });
  $tabs = $('.swm-AccordionA_Tab');

  for (var i = 0, len = $tabs.length; i < len; i++) {
    var $tab = $tabs.eq(i);
    var $child, children = [];
    $child = $tab.next();
    var safeCount = 100;
    var count = 0;
    while ((0 < $child.length) && !$child.hasClass('swm-AccordionA_Tab')) {
      children.push($child);
      $child = $child.next();
      if ($child.hasClass('swm-AccordionA_TabEnd') || $child.find('.swm-AccordionA_TabEnd').length) {
        break;
      }
      count++;
      if (safeCount <= count) {
        break;
      }
    }
    var $obj = $('<div class="' + tabPanelClass + '"></div>');
    $obj.append(children);
    $tab.after($obj);
  }

  // アコーディオン設定
  var idPrefix = 'accb-';
  var $tabPanels = $('.' + tabPanelClass);
  $tabs.each(function (index, elm) {
    $elm = $(elm);
    setAccordion($elm, $elm.next(), $tabs, $tabPanels, idPrefix + index);
  });

  hideTab($tabs, $tabPanels);

  /**
   * 初期設定：
   * 最初のタブを表示させる。
   */
  $tabs.eq(0).attr('aria-expanded', 'true').addClass(tabClass);
  $tabPanels.eq(0).attr('aria-hidden', 'false').addClass(panelClass);
}());