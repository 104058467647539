/**
 * ニュースサイドバーのカレント表示を切り替えます。
 *
 * CMSでは/eu/newsのようにnewsの前にパスを挟むため、正規表現で判定する場合は留意
 */
$(function() {

  // set current ... top, year
  if(location.pathname.match(/\/news\//g)) {
    // top
    if(location.pathname.match(/\/news\/$/g) || location.pathname.match(/\/news\/[^/]*\.html$/g)) {
      $('.l-DetailTemplate_SubNav a[data-mark-current="latest"]').attr('aria-current', 'location');
    // year
    } else {
      var current = location.pathname.replace(/^.*\/news\/([\d]{4})\/.*$/g, "$1");
      if (current) {
        $('.l-DetailTemplate_SubNav a[data-mark-current="' + current + '"]').attr('aria-current', 'location');
      }
    }
  }

  // set current ... category
  if (0 < $(".st-CategoryNavNews").length) {

    if(location.pathname.match(/\/news\//g)) {
      // URLからカテゴリ名取得
      var m = location.pathname.match(/.*\/news\/([^/]*)\/.*$/);
      var currentCategory = (m && (1 < m.length)) ? m[1] : "";
      // 現在のページがカテゴリ一覧かどうか（年月一覧でないか）チェック
      var isCategoryName = currentCategory && !currentCategory.match(/[\d]{4}/);
      if (currentCategory && isCategoryName) {
        // currentをクリア
        $(".st-SideNavNews_ParentLink, .st-CategoryNavNews_Link").removeAttr(
          "aria-current"
        );
        $(
          // PCメニュー
          ".st-SideNavNews_ParentLink[data-mark-current=" + currentCategory + "]" +
          // SPメニュー
          ", .st-CategoryNavNews_Link[data-mark-current=" + currentCategory + "]"
        ).attr("aria-current", "location");
      }
    } else {
      // Newsページ以外の場合はLatestをカレント表示
      $(".st-CategoryNavNews_Link:first").attr("aria-current", "location");
    }
  }

  // mobile menu toggle
  $(".st-CategoryNavNews").each(function() {
    var $parent = $(this);
    var $trigger = $parent.find(".st-CategoryNavNews_Parent");
    var $body = $parent.find(".st-CategoryNavNews_List");

    function categoryExpand() {
      var expanded = $parent.attr("aria-expanded") == "true";
      $parent.attr("aria-expanded", expanded ? "false" : "true");
      $body.attr("aria-hidden", expanded ? "true" : "false");
    }

    $trigger.on("click", categoryExpand);
    categoryExpand();
  });
});
