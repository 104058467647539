/**
 * サイドバーのカレント表示やトグルを切り替えます。
 */
$(function() {
  var $link = $('.st-SideNav_Link');
  var $toggle = $('#st-SideNav_Toggle');
  var $parent = $('#st-SideNav_Parent');
  var $parentLink = $('.st-SideNav_ParentLink');
  var $childLink = $('.st-SideNav_ChildLink');
  var locationPathname = getPathName();
  var directories = locationPathname.split("/");
  var category = '/' + directories[1] + '/';
  var thirdLabel = '/' + directories[1] + '/' + directories[2] + '/' + directories[3] + '/';
  var fourthLabel = '/' + directories[1] + '/' + directories[2] + '/' + directories[3] + '/' + directories[4] + '/';

  var init = function() {
    // $toggle.attr('aria-expanded', 'false');
    // $parent.attr('aria-hidden', 'true');

    addCurrentLink();
    addCurrentParentLink();
    addCurrentChildLink();
    productsExpand();
    toggleExpand();
    onAnimation();
  }();

  /**
   * パスネーム取得
   * 
   * cms環境の場合、先頭に/eu/といった国ディレクトリが入るためそれを除く
   * @return string
   */
  function getPathName() {
    var match = location.pathname.match(/\/(?:([a-zA-z]{2})\/)?(.*)/);
    return (match[1]) ? '/' + match[2] : location.pathname;
  }

  /**
   * キャメルケースへ変換 sampleString
   * @param string
   * @return string
   */
  function camelCase(str){
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/[-_](.)/g, function(match, group1) {
        return group1.toUpperCase();
    });
  }

  /**
   * パスカルケースへ変換 SampleString
   * @param string
   * @return string
   */
  function pascalCase(str){
    var camel = camelCase(str);
    return camel.charAt(0).toUpperCase() + camel.slice(1);
  }

  /**
   * 第一階層のリンクにカレントを表示します。
   */
  function addCurrentLink() {
    $link.each(function(index, elm) {
      if (locationPathname.replace(/index\.html$/, '') === $(elm).data('mark-current')) {
        // 同じURLであればカレント表示する
        $(elm).attr('aria-current', 'true');
      }
    });


    var includedCategories = [
      'news',
      'events',
      'contact',
      'service',
      'download',
      'company',
      'career'
    ];

    // Products配下の`includedCategories`がカレントの場合 サブリストを可視化
    includedCategories.forEach(function(cat) {
      if((locationPathname.indexOf(cat) != -1)) {
        var selector = 'st-SideNav_Sub' + pascalCase(cat);
        $('#' + selector).attr('aria-hidden', 'false');
      }
    });
    $('ul.st-SideNav_Parent').each(function(){
      var $elm = $(this);
      var mark = $elm.data('mark-parent');
      if ( mark && (locationPathname.indexOf(mark) != -1)) {
        $elm.attr('aria-hidden', 'false');
      }
    });
  }

  /**
   * 第二階層のリンクにカレントを表示します。
   */
  function addCurrentParentLink() {
    $parentLink.each(function(index, elm) {
      var $elm = $(elm);
      var mark = $elm.data('mark-current');
      isThirdLabelLocation = thirdLabel === mark;
      isFourthLocation = fourthLabel === mark;
      isPage = getPathName().replace(/index\.html$/, '') === mark;

      if (isPage) {
        $elm.attr('aria-current', 'page');
        return;
      }

      if (isThirdLabelLocation || isFourthLocation) {
        $elm.attr('aria-current', 'location');
      }
    });
  }

  /**
   * 第三階層のリンクにカレントを表示します。
   */
  function addCurrentChildLink() {
    $childLink.each(function(index, elm) {
      if (getPathName() === $(elm).data('mark-current')) {
        // 同じURLであればカレント表示する
        $(elm).attr('aria-current', 'true');
      }
    });
  }

  /**
   * トグルの初期表示を切り替えます。
   * Products以下、特定のカテゴリが含まれていない場合に開きます。
   */
  function productsExpand() {
    var excludedCategories = [
      'news',
      'events',
      'contact',
      'service'
    ];

    var hasProcuts = category === '/products/' ? true: false;
    var hasExcludedCategories = false;

    excludedCategories.forEach(function(category) {
      if (locationPathname.indexOf('/' + category + '/') !== -1) {
        return hasExcludedCategories = true;
      }
    });

    if (hasProcuts === true && hasExcludedCategories === false) {
      $toggle.attr('aria-expanded', 'true');
      $parent.attr('aria-hidden', 'false');
    }

  }

  /**
   * クリック時にトグルを切り替えます。
   */
  function toggleExpand() {
    $toggle.on('click', function(e) {
      var isExpanded = $(this).attr('aria-expanded') === 'true';

       if (isExpanded) {
        $(this).attr('aria-expanded', 'false');
        $parent.attr('aria-hidden', 'true');
      } else {
        $(this).attr('aria-expanded', 'true');
        $parent.attr('aria-hidden', 'false');
      }

      e.stopPropagation();
    });
  }

  /**
   * load時にはアニメーションしないように時間差でクラスを追加します。
   */
  function onAnimation() {
    window.setTimeout(function() {
      $toggle.addClass('st-SideNav_Toggle-onAnimation');
    }, 3000);
  }

});
