/**
 * Region切り替えのトグルです。スマホとPCは別のHTMLです。
 * 展開時に他のナビゲーションが開いていた場合は、他のナビゲーションを強制的に閉じます。
 */
$(function() {
  var toggleSp = document.getElementById('st-GlobalRegionSp_Toggle');
  var subAreaSp = document.getElementById('st-GlobalRegionSp_SubArea');

  var $togglePc = $('#st-GlobalRegionPc_Toggle');
  var $subAreaPc = $('#st-GlobalRegionPc_SubArea');
  var $close = $('.st-GlobalNav_RegionClose');
  var $firstLink = $subAreaPc.find('a').eq(0);
  var $lastLink = $subAreaPc.find('a').eq(-1);
  var wait = (1000 / 60); // 60fps
  var keys = {
    tab: 9
  };

  var init = function() {
    setWidth();

    $(window).on('resize', _.throttle(function() {
      setWidth();
    }, wait));

    onToggleSp();
    onTogglePc();
    focusToggleToFirstLink();
    focusFirstLinkToToggle();
    focusLastLinkToToggle();
  }();

  // トグルするナビゲーションに`width`を設定する
  function setWidth() {
    $subAreaPc.css('width', $(window).outerWidth(true));
  }

  // スマホ版のトグル
  function onToggleSp() {
    if (!toggleSp) return;
    toggleSp.addEventListener('click', function(e) {
      var controls = this.getAttribute('aria-expanded') === 'true';

      if (controls) {
        this.setAttribute('aria-expanded', 'false');
        subAreaSp.setAttribute('aria-hidden', 'true');
      } else {
        this.setAttribute('aria-expanded', 'true');
        subAreaSp.setAttribute('aria-hidden', 'false');
      }
      e.stopPropagation();
    });
  }

  // PC版のトグル
  function onTogglePc() {
    $togglePc.on('click', function(e) {
      var controls = $(this).attr('aria-expanded') === 'true';

      if (controls) {
        $(this).attr('aria-expanded', 'false');
        $subAreaPc.attr('aria-hidden', 'true').slideUp(400);
        $close.attr('aria-hidden', 'true').hide();
      } else {
        $(this).attr('aria-expanded', 'true');
        $subAreaPc.attr('aria-hidden', 'false').slideDown(400);
        $close.attr('aria-hidden', 'false').show();
      }
      e.stopPropagation();
    });
  }

  // toggleでTabキーを押すとナビゲーション内にフォーカスする
  function focusToggleToFirstLink() {
    $togglePc.on('keydown', function(e) {
      if(!e.shiftKey && e.which === keys.tab) {
        setTimeout(function() {
          $firstLink.focus().addClass('focus-ring');
        });
      }
    });
  }

  // ナビゲーション内の最後のリンクでShift + Tabキーを押すと、Toggleにフォーカスする（戻る）
  function focusFirstLinkToToggle() {
    $firstLink.on('keydown', function(e) {
      if (e.shiftKey && e.which === keys.tab) {
        setTimeout(function() {
          $togglePc.focus().addClass('focus-ring');
        });
      }
    });
  }

  // ナビゲーション内の最後のリンクでTabキーを押すと、Toggleにフォーカスする
  function focusLastLinkToToggle() {
    $lastLink.on('keydown', function(e) {
      if (!e.shiftKey && e.which === keys.tab) {
        e.preventDefault();
        setTimeout(function() {
          $togglePc.focus().addClass('focus-ring');
        });
      }
    });
  }

}());
