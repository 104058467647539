/**
 * 年別、月別一覧表示の時の記事の表示調整
 * ニュースの月切り替えタブ
 * ページング表示調整
 */
$(function () {
  // 1ページに表示する記事件数
  var CountPerPage = 20;

  // 年・年月アーカイブの表示調整
  var setDisplayYearMonthArchive = function () {

    // URLから年、月、ページ番号を取得
    var parseMonthPage = function () {
      var year = 0, month = 0, currentPage = 1, category = '';
      // カテゴリアーカイブ
      var match = window.location.pathname.match(/news\/([a-z_-]+)\/(index_?([0-9]{1,2})?)?/);
      if (match) {
        if (match[1]) {
          category = match[1];
        }
        if (match[3]) {
          currentPage = parseInt(match[3], 10);
        }
      } else {
        // カテゴリ年、カテゴリ月、年、月アーカイブ
        match = window.location.pathname.match(/([a-z_-]+)?\/news\/(([0-9]{4})\/)?(([0-9]{2})\/)?(index_?([0-9]{1,2})?)?/);
        if (match) {
          if (match[1]) {
            category = match[1];
          }
          if (match[2]) {
            year = parseInt(match[2], 10);
          }
          if (match[4]) {
            month = parseInt(match[4], 10);
          }
          if (match[7]) {
            currentPage = parseInt(match[7], 10);
          }
        }
      }
      return [year, month, category, currentPage];
    }
    var r = parseMonthPage();
    var year = r[0];
    var month = r[1];
    var category = r[2];
    var currentPage = r[3];
    var isYearPage = year != 0 && month == 0;
    var isMonthPage = year != 0 && month != 0;

    // title, meta更新
    var setHeadAttr = function (year, month, category) {
      var prefix = '';
      if (year && category) {
        prefix = year + ' | ';
      }
      if (month) {
        monthLabel = $('a', ".news-BoxA_FilterItem[data-month='" + month + "']").text();
        prefix = monthLabel + ' | ' + prefix;
      }
      var title = prefix + $(document).attr("title");
      $(document).attr("title", title);
      $("meta[property='og:title'], meta[name='twitter:title']").attr('content', title);
      $("meta[property='og:url'], meta[name='twitter:url']").attr('content', window.location.href);
    };
    setHeadAttr(year, month, category);

    // 表示・非表示対象の記事リストを取得
    var getAllNewsList = function () {
      var $listOfCurrent = $();
      $('.js-news-BoxA_Item').each(function (idx, elm) {
        var $elm = $(elm);
        $listOfCurrent = $listOfCurrent.add($elm);
      });

      // 現在のページ内の記事
      var pageCount = 0;
      var $targetList = $listOfCurrent;
      pageCount = parseInt($targetList.length / CountPerPage, 10);
      if (($targetList.length % CountPerPage) !== 0) {
        pageCount += 1;
      }
      var begin = (currentPage - 1) * CountPerPage;
      var end = begin + CountPerPage;
      var $displayList = $();
      var $notDisplayList = $();
      for (var i = 0, len = $targetList.length; i < len; i++) {
        if (begin <= i && i < end) {
          $displayList = $displayList.add($targetList[i]);
        } else {
          $notDisplayList = $notDisplayList.add($targetList[i]);
        }
      }
      return [[], $displayList, $notDisplayList, pageCount];
    };
    var getYearMonthNewsList = function (year, month, isMonthPage) {
      // 0:未使用 1:1月...12:12月
      var enableMonth = [false,
        false, false, false, false,
        false, false, false, false,
        false, false, false, false
      ];

      var $listOfCurrentYear = $();
      var $listOfOtherYear = $();
      var $listOfOtherMonth = $();
      var $listOfCurrentMonth = $();
      $('.js-news-BoxA_Item').each(function (idx, elm) {
        var $elm = $(elm);
        if (elm.dataset.published_year == year) {
          $listOfCurrentYear = $listOfCurrentYear.add($elm);

          enableMonth[elm.dataset.published_month] = true;
          var isCurrentMonthEntry = elm.dataset.published_month == month;
          if (isCurrentMonthEntry) {
            $listOfCurrentMonth = $listOfCurrentMonth.add($elm);
          } else {
            $listOfOtherMonth = $listOfOtherMonth.add($elm);
          }
        } else {
          $listOfOtherYear = $listOfOtherYear.add($elm);
          $listOfOtherMonth = $listOfOtherMonth.add($elm);
        }
      });

      // 現在のページ内の記事
      var pageCount = 0;
      var $targetList = (isMonthPage) ? $listOfCurrentMonth : $listOfCurrentYear;
      pageCount = parseInt($targetList.length / CountPerPage, 10);
      if (($targetList.length % CountPerPage) !== 0) {
        pageCount += 1;
      }
      var begin = (currentPage - 1) * CountPerPage;
      var end = begin + CountPerPage;
      var $displayList = $();
      var $notDisplayList = $();
      for (var i = 0, len = $targetList.length; i < len; i++) {
        if (begin <= i && i < end) {
          $displayList = $displayList.add($targetList[i]);
        } else {
          $notDisplayList = $notDisplayList.add($targetList[i]);
        }
      }
      if (isMonthPage) {
        $notDisplayList = $notDisplayList.add($listOfOtherMonth);
      } else if (isYearPage) {
        $notDisplayList = $notDisplayList.add($listOfOtherYear);
      }

      return [enableMonth, $displayList, $notDisplayList, pageCount];
    }

    if (isYearPage || isMonthPage) {
      r = getYearMonthNewsList(year, month, isMonthPage);
    } else {
      r = getAllNewsList();
    }
    var enableMonth = r[0];
    var $displayList = r[1];
    var $notDisplayList = r[2];
    var pageCount = r[3];

    // 記事がなければ年別一覧に戻す
    if (!$displayList.length) {
      window.location.href = "../";
      return;
    }
    // 現在の月・ページの記事以外を非表示
    $notDisplayList.hide();

    // 月選択エリアの表示更新
    var setMonthLinkState = function (enableMonth, isMonthPage, month) {
      $(".news-BoxA_FilterItem").each(function (i, o) {
        var $a = $('a', o);
        var ariaCurrent = false;
        var isYearLink = o.dataset.month == 0;
        var href = '';
        if (isYearLink) {
          if (!isMonthPage) {
            ariaCurrent = 'location';
            href = './';
          } else {
            href = '../';
          }
        } else {
          var isCurrentMonthPage = o.dataset.month == month;
          if (isCurrentMonthPage) {
            ariaCurrent = 'location';
            if (window.innerWidth < 768) {
              $(".news-BoxA_Filter").scrollLeft($a.offset().left);
            }
          } else if (!enableMonth[o.dataset.month]) {
            // aタグをspanに変更
            $a.contents().unwrap('<a/>').wrap('<span/>').parent()
              .addClass('news-BoxA_FilterLink')
              .attr('aria-disabled', true);
          }

          var monthLabel = o.dataset.month;
          if (monthLabel.length <= 1) {
            monthLabel = '0' + monthLabel;
          }
          if (isMonthPage) {
            href = '../' + monthLabel + '/';
          } else {
            href = './' + monthLabel + '/';
          }
        }
        $a.attr({
          'aria-current': ariaCurrent,
          'href': href
        });
      });
    }
    if (isYearPage || isMonthPage) {
      setMonthLinkState(enableMonth, isMonthPage, month);
      // 非表示用クラスを除去して表示
      $('.js-news-BoxA_Header').attr('aria-hidden', "false");
    } else {
      // マージン相殺の位置関係がずれるため、要素を削除
      $('.js-news-BoxA_Header').remove();
    }

    // Pager設定
    // 月アーカイブはページ表示をjsで制御するため、ページャーもjsで調整
    var setPager = function (CountPerPage, currentPage, pageCount) {
      // 現在のページ前後2ページづつ表示
      // ページがない場合は、合計5ページ分表示する
      var leftPageNo, rightPageNo;
      var displayMargin = 2;
      var offset = 0;
      if (currentPage <= displayMargin) {
        leftPageNo = 1
        offset = displayMargin - currentPage + 1;
        rightPageNo = Math.min((currentPage + displayMargin + offset), pageCount);
      } else if (pageCount < (currentPage + displayMargin)) {
        rightPageNo = pageCount;
        offset = ((currentPage + displayMargin) - pageCount);
        leftPageNo = Math.max((currentPage - displayMargin - offset), 1);
      } else {
        leftPageNo = Math.max((currentPage - displayMargin), 1);
        rightPageNo = Math.min((currentPage + displayMargin), pageCount);
      }

      // 表示調整
      $(".sw-Pager").each(function () {
        $('.js-Pager_LocationItemCurrentPage', this).text(currentPage);
        $('.js-Pager_LocationItemLastPage', this).text(pageCount);

        var prevPageNo = currentPage - 1;
        if (prevPageNo <= 0) {
          $('.js-Pager_Prev', this).hide();
        } else {
          var htmlName = 'index' + (prevPageNo > 1 ? '_' + prevPageNo : '') + '.html';
          $('a.sw-Pager_PrevLink', this).attr('href', htmlName);
        }
        var nextPageNo = currentPage + 1;
        if (pageCount < nextPageNo) {
          $('.js-Pager_Next', this).hide();
        } else {
          $('a.sw-Pager_NextLink', this).attr('href', 'index_' + nextPageNo + '.html');
        }

        $('.sw-Pager_Item', this).each(function (idx) {
          var no = leftPageNo + idx;
          var ariaCurrent = false;
          if (rightPageNo < no) {
            $(this).hide();
          } else {
            var htmlName = 'index' + (no > 1 ? '_' + no : '') + '.html';
            $('a', this).attr('href', htmlName).text(no);
            if (no == currentPage) {
              ariaCurrent = 'page';
            }
          }
          $('a', this).attr('aria-current', ariaCurrent);
        });
      });
    }
    setPager(CountPerPage, currentPage, pageCount);
    // 非表示用クラスを除去して表示
    $('.js-Pager').attr("aria-hidden", "false");

    // パンくず
    var $breadCrumbs;
    if (isYearPage) {
      $breadCrumbs = $('.js-st-Breadcrumb_ItemNews-year');
      $breadCrumbs.text(year);
      $breadCrumbs = $breadCrumbs.add($('.js-st-Breadcrumb_ItemNews-yearmonth'));
      $breadCrumbs.attr('aria-hidden', 'false');
    } else if (isMonthPage) {
      $breadCrumbs = $('.js-st-Breadcrumb_ItemNews-month');
      $('a', $breadCrumbs).attr('href', '../../' + year + '/index.html').text(year);
      $breadCrumbs = $breadCrumbs.add($('.js-st-Breadcrumb_ItemNews-yearmonth'));
      $breadCrumbs = $breadCrumbs.add($('.js-st-Breadcrumb_ItemNews-month' + month));
    } else {
      $breadCrumbs = $('.js-st-Breadcrumb_ItemNews-all');
    }
    $breadCrumbs.attr('aria-hidden', 'false');
    // タイトル
    if (isYearPage || isMonthPage) {
      var $title = $('.js-st-TitleC_TitleNews-yearmonth');
      $title.eq(1).text(year);
      $title.attr('aria-hidden', 'false');
    } else {
      $('.js-st-TitleC_TitleNews-all').attr('aria-hidden', 'false');
    }

    // 非表示用クラスを除去して表示
    $('.js-news-BoxA').attr('aria-hidden', 'false');
  };

  // mobile menu set scrollX
  var setMontlyMenuScroll = function () {
    $(".news-BoxA_Header").each(function () {
      var $parent = $(this);
      var $filter = $(".news-BoxA_Filter");
      if ($filter.length) {
        function getScrollPosition(e) {
          var l = $filter.scrollLeft();
          var endPoint = $filter[0].scrollWidth - window.innerWidth;

          $parent[l >= endPoint ? "addClass" : "removeClass"]("is-Last");
          $parent[!l ? "addClass" : "removeClass"]("is-First");
        }

        $filter.on("scroll", getScrollPosition);
        getScrollPosition();
      }
    });

  };

  // cms: カテゴリ、カテゴリ別年、カテゴリ別年月、年、年月アーカイブはjsでページング表示調整
  // 年月アーカイブ出力時間のコストが大きいため
  if ($('.news-BoxA.js-news-BoxA_YearMonth').length) {
    // 年・年月
    setDisplayYearMonthArchive();
    // mobile menu set scrollX
    setMontlyMenuScroll();

    // 全件表示のときはjsのコストが大きくなる（常に記事数が増える）ため、MTでページング後、表示調整
  } else {

    // mobile menu set scrollX
    setMontlyMenuScroll();

    // ページング表示調整
    // CMSでページ出力を制限できないため、jsで不要なページ数を非表示にする
    $(".sw-Pager_List").each(function () {
      var $currentLink = $('.sw-Pager_Link[aria-current="page"]', this);
      var pageNo = $currentLink.data('page-no');
      var totalPageNo = $('.sw-Pager_Link:last', this).data('page-no');

      // 現在のページ前後2ページづつ表示
      // ページがない場合は、合計5ページ分表示する
      var leftPageNo, rightPageNo;
      var displayMargin = 2;
      var offset = 0;
      if (pageNo <= displayMargin) {
        leftPageNo = 1;
        offset = displayMargin - pageNo + 1;
        rightPageNo = Math.min((pageNo + displayMargin + offset), totalPageNo);
      } else if (totalPageNo < (pageNo + displayMargin)) {
        rightPageNo = totalPageNo;
        offset = ((pageNo + displayMargin) - totalPageNo);
        leftPageNo = Math.max((pageNo - displayMargin - offset), 1);
      } else {
        leftPageNo = Math.max((pageNo - displayMargin), 1);
        rightPageNo = Math.min((pageNo + displayMargin), totalPageNo);
      }
      $('.sw-Pager_Item', this).each(function () {
        var no = $('.sw-Pager_Link', this).data('page-no');
        if ((no < leftPageNo) || (rightPageNo < no)) {
          $(this).hide();
        }
      });
      // 非表示用クラスを除去して表示
      $('.js-Pager').attr("aria-hidden", "false");
    });
  }
});
