/**
 * ページ内リンクを生成します。
 * リンクをクリックするとスムーススクロールします（）。
 */
$(function() {
  var $pageNav = $('.swm-PageNavA').filter(function(index, elm){
    return 0 === $(elm).parents('.cms-Component_Editor').length;
  });
  var $headings = $('h2.sw-Heading2A, h2.sw-Heading2B');

  if (!$pageNav.length) return;
  if (!$headings.length) return;

  var init = function() {
    createTOC();
  }();

  function createTOC() {
    var list = '';

    $headings.each(function(i) {
      var index = i + 1;
      var headingID = $(this).attr('id');

      if (!headingID) {
        headingID = 'ref' + index;
        $(this).attr('id', 'ref' + index);
      }

      list += '<li class="swm-PageNavA_Item"><a class="swm-PageNavA_Link js-smoothScroll" href="#' + headingID + '"><span class="swm-PageNavA_Icon" aria-hidden="true"></span>' + $(this).text() + '</a></li>';
    });
    $pageNav.empty().prepend('<ul class="swm-PageNavA_List">' + list + '</ul>');
  }

}());
